import { useRouterLinkContext } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DestinationSelector } from './DestinationSelector';
import { SearchInputTrigger } from './SearchInputTrigger';
import { Popover } from '../Popover/Popover';
import { PopoverOrModal } from '../Popover/PopoverOrModal';
import { Option } from '../types';
import { AccommodationSuggestion } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { useSearchResultsPageUrl } from '@Components/SearchForm/useSearchResultsPageUrl';
import { unique } from '@Core/helpers/array';
import { useDestinationAutocompleteTracking } from '@Core/tracking/hooks/useDestinationAutocompleteTracking';
import { AutocompleteEventType } from '@Core/tracking/types';
import { usePathTemplate } from '@Core/usePathTemplate';
import { usePandaPageUrl } from '@Pages/search-results/usePandaPageUrl';
import { useSearchSelectionStore, useStoreContext } from '@Stores/StoreContext';
import { useDestinationLookup } from '@UX/components/Search/DestinationInput/useDestinationLookup';

export interface DestinationInputProps extends ClassNameProps {
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
  allowTagsRemoval?: boolean;
}

export const DestinationInput: React.FC<DestinationInputProps> = ({
  className,
  hasInputTitle,
  showInputTitleOnMobile,
  allowTagsRemoval,
}) => {
  // core hooks
  const { t } = useTranslation();
  const history = useHistory();
  const { useHref } = useRouterLinkContext();
  const getSearchResultsPageUrl = useSearchResultsPageUrl();
  const { getPandaUrlParams } = usePandaPageUrl();
  const pandaPath = usePathTemplate('panda');
  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  // tracking
  const [
    destinationInputChangedTracking,
    destinationOptionSelectedTracking,
    destinationOptionsLoadedTracking,
  ] = useDestinationAutocompleteTracking('DestinationInput', [
    AutocompleteEventType.inputChanged,
    AutocompleteEventType.optionSelected,
    AutocompleteEventType.optionsLoaded,
  ]);

  // search selection state
  const { searchSelection: searchSelectionStore } = useStoreContext();
  const [destinationIds, setDestinationIds] = useSearchSelectionStore((state) => [
    state.destinationIds,
    state.setDestinationIds,
  ]);

  // destination state
  const [savedDestinationIds, setSavedDestinationIds] = useState<string[]>(destinationIds);

  // search term state
  const [searchTerm, setSearchTerm] = useState('');

  const { destinationOptions, selectedDestinationsOptions, lookupResults, isLookupLoading } =
    useDestinationLookup({
      searchTerm,
      pauseLookup: !isModalOpen,
      destinationInputChangedTracking,
      destinationOptionSelectedTracking,
      destinationOptionsLoadedTracking,
    });

  // modal effects
  useEffect(() => {
    if (isModalOpen) {
      setSavedDestinationIds(destinationIds);
    } else {
      setSearchTerm('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // callbacks
  const onHotelClick = useCallback(
    (option: Option) => {
      const hotel = destinationOptions.find(
        (item) => item.id === option.value,
      ) as AccommodationSuggestion;

      if (!hotel) {
        return;
      }

      destinationOptionSelectedTracking({
        searchTerm,
        options: lookupResults || [],
        selectedOptions: [option, ...selectedDestinationsOptions],
        isOptionsLoaded: !isLookupLoading,
        isListOpened: true,
      });

      const selection = searchSelectionStore.getState();

      if (hotel.isLimitlessOnly) {
        history.push({
          pathname: pandaPath,
          search: getPandaUrlParams(hotel.id, selection),
        });
      } else if (hotel.isStaticHotel) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        window.location.href = useHref(
          getSearchResultsPageUrl({
            type: 'HOSRP',
            selection,
            masterId: hotel.id,
          }),
        );
      } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        window.location.href = useHref(
          getSearchResultsPageUrl({
            type: 'DPSRP',
            selection: selection.extend({
              destinationIds: unique(
                [
                  ...selection.destinationIds,
                  `${hotel.accommodation?.destination?.resort?.id || ''}`,
                ].filter(Boolean),
              ),
              pinnedMasterIds: [Number(hotel.id)],
            }),
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      destinationOptions,
      isLookupLoading,
      lookupResults,
      pandaPath,
      searchTerm,
      selectedDestinationsOptions,
    ],
  );

  const onCancel = useCallback(() => {
    setDestinationIds(savedDestinationIds);
    setModalClose();
  }, [savedDestinationIds, setModalClose, setDestinationIds]);

  const onRemoveTag = useCallback<(destinationId: string) => void>(
    (destinationId) => {
      setDestinationIds(destinationIds.filter((id) => id !== destinationId));
    },
    [destinationIds, setDestinationIds],
  );

  const trigger = (
    <SearchInputTrigger
      onOpen={setModalOpen}
      onRemoveTag={onRemoveTag}
      className={className}
      hasInputTitle={hasInputTitle}
      showInputTitleOnMobile={showInputTitleOnMobile}
      allowTagsRemoval={allowTagsRemoval}
    />
  );

  return (
    <PopoverOrModal
      isOpen={isModalOpen}
      popover={
        <Popover
          className={className}
          size="Medium"
          isOpen={isModalOpen}
          anchorPosition={hasInputTitle ? 'top-left' : 'top-left-nopadding'}
          trigger={trigger}
          onClickOutside={setModalClose}
          contentStyle={{
            paddingX: 'xs',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <DestinationSelector
            onHotelClick={onHotelClick}
            onChangeSearchTerm={setSearchTerm}
            closeModal={setModalClose}
            searchTerm={searchTerm}
            selectedDestinationOptions={selectedDestinationsOptions}
            isLoading={isLookupLoading}
            lookupResults={lookupResults}
            mainContentWrapperStyles={{
              maxHeight: '50vh',
              overflowY: 'auto',
              marginX: '-xs',
              paddingX: 'xs',
              paddingBottom: 'l',
            }}
          />
        </Popover>
      }
      modal={
        <Fragment>
          {trigger}
          <LoadableModal
            show={isModalOpen}
            data-id="modal-container"
            onClose={setModalClose}
            Actions={
              <Fragment>
                <Button
                  data-id="cancel-btn"
                  variant="TextSecondary"
                  size="48"
                  onClick={onCancel}
                >
                  {t('cancel')}
                </Button>
                <Button
                  data-id="done-btn"
                  variant="PrimaryDefault"
                  size="48"
                  onClick={setModalClose}
                >
                  {t('done')}
                </Button>
              </Fragment>
            }
            Content={
              <DestinationSelector
                onHotelClick={onHotelClick}
                onChangeSearchTerm={setSearchTerm}
                closeModal={setModalClose}
                searchTerm={searchTerm}
                selectedDestinationOptions={selectedDestinationsOptions}
                isLoading={isLookupLoading}
                lookupResults={lookupResults}
              />
            }
          />
        </Fragment>
      }
    />
  );
};
