import React, { createContext, useContext } from 'react';

export type SearchType = 'DP' | 'HO' | 'NOT_SET';

export type SearchTypeContextProps = { origin: SearchType };

const SearchTypeContext = createContext<SearchTypeContextProps>({ origin: 'NOT_SET' });

export const useSearchTypeContext = () => useContext(SearchTypeContext);

export const DynamicPackageSearchContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => <SearchTypeContext.Provider value={{ origin: 'DP' }}>{children}</SearchTypeContext.Provider>;

export const HotelOnlySearchContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <SearchTypeContext.Provider value={{ origin: 'HO' }}>{children}</SearchTypeContext.Provider>
);
